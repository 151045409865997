<template >
  <div class="order-details">
    <div id="orderDetaisMsge">
      <div class="head_nav">
        <van-nav-bar class="head_nar"
                     title="订单详细">
          <template #left>
            <van-icon name="arrow-left"
                      size="1.5rem"
                      color="#fff"
                      v-permission="{'OrderDetails':'OrderDetails'}"
                      @click="back()" />
          </template>
        </van-nav-bar>
      </div>
      <div id="mapDiv"
           v-if="state.isKsyMap"></div>
      <div id="containerrete"
           v-if="state.isGaMap"></div>
      <div id="panel"></div>
      <van-popup v-model:show="wxshow"
                 position="top"
                 :style="{ height: '40%',width:'100%'}">
        <img :src="state.Tips"
             class="tipsgost">
      </van-popup>
      <div class="routereds">
        <div class="orderDivee">
          <div class="orderMsg">
            <van-row>
              <van-col span="1"></van-col>
              <van-col span="23">
                <h5>用户信息</h5>
              </van-col>
            </van-row>
            <van-cell-group>
              <van-cell title="下单用户"
                        :value="state.orderInfo.userNickName" />
              <van-cell title="联系用户"
                        v-if="state.ContactUser">
                <template #right-icon>
                  <a :href="'tel:' + state.orderInfo.userPhone">
                    <van-icon name="phone-o"
                              size="1.2rem"
                              color="#02b6fd" />
                    <a style="color: #02b6fd">电话</a>
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <div style="color: #969799"
                       @click="chat"
                       v-if="state.UserChat">
                    <van-icon name="chat-o"
                              size="1.2rem" />在线
                  </div>
                </template>
              </van-cell>
              <van-cell title="下单地址"
                        :value="state.orderInfo.startDetailAddress" />
              <p v-if="state.isUserType == 'Driver'">
                <van-row v-if="state.Suborderstatus">
                  <van-col span="18"></van-col>
                  <van-col span="6"
                           class="hosertywe">
                    <van-button type="primary"
                                size="normal"
                                @click="Navigationtes">
                      <van-icon name="logistics"
                                v-permission="{'MapDownload':'MapDownload'}" />导航
                    </van-button>
                  </van-col>
                  <van-action-sheet v-model:show="shows"
                                    cancel-text="取消"
                                    description="地图选择"
                                    :actions="actionses"
                                    @select="onSelectes" />
                </van-row>
              </p>
              <van-row v-if="state.orderInfo.Paymentdisplay">
                <van-col span="1"></van-col>
                <van-col span="22"
                         class="dingy">
                  <van-button v-permission="{'PayIndex':'PayIndex'}"
                              type="warning"
                              block
                              class="hoshq"
                              size="normal"
                              @click="payment(state.orderInfo.outTradeNo)">去付款</van-button>
                </van-col>
                <van-col span="1"></van-col>
              </van-row>

            </van-cell-group>
          </div>
          <!--  司机信息 -->
          <div class="orderMsg"
               v-if="state.isShowDriverinfo == true">
            <div v-if="state.isUserType == 'User'">
              <van-row>
                <van-col span="1"></van-col>
                <van-col span="23">
                  <h5>司机信息</h5>
                </van-col>
              </van-row>
              <van-cell-group>
                <div>
                  <van-cell title="配送司机"
                            v-if="state.isUserType == 'User'">
                    <template #right-icon>
                      <a :href="'tel:' +state.orderInfo.driverPhone">
                        <van-icon name="phone-o"
                                  size="1.2rem"
                                  color="#02b6fd" />
                        <a style="color: #02b6fd">{{ state.orderInfo.driverRealName }}</a>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <div style="color: #969799"
                           @click="chat"
                           v-if="state.UserChat">
                        <van-icon name="chat-o"
                                  size="1.2rem" />在线
                      </div>
                    </template>
                  </van-cell>
                </div>
                <div>
                  <van-cell title="车牌号"
                            v-if="state.isUserType == 'User'">
                    <template #right-icon>
                      <div class="number">{{state.orderInfo.carLicensePlate}}</div>
                    </template>
                  </van-cell>
                </div>
              </van-cell-group>
            </div>
          </div>
          <!-- 订单信息 -->
          <div class="orderMsg">
            <van-row>
              <van-col span="1"></van-col>
              <van-col span="23">
                <h5>订单信息</h5>
              </van-col>
            </van-row>
            <van-cell-group>
              <van-cell title="订单号">
                <template #right-icon>
                  <label class="odere">{{state.orderInfo.orderNo}}</label>
                </template>
              </van-cell>
              <van-cell title="erp订单号"
                        v-if="state.orderInfo.outsideOrderNo==null">
                <template #right-icon>
                  <label class="odere">{{state.orderInfo.outsideOrderNo}}</label>
                </template>
              </van-cell>
              <van-cell title="订单状态"
                        :value="orderStatustonn(state.orderInfo.orderStatus)" />
              <van-cell title="车辆类型"
                        :value=" state.orderInfo.carName" />
              <div class="XerP"
                   v-permission="{'OrderDetails':'OrderDetails'}">
                <van-cell title="XerP详情"
                          value="订单详情"
                          v-if="XerPUrl"
                          @click="XerPOrder" />
              </div>
              <van-cell :title="condition(state.orderInfo.goodsCalcRuleType)">
                <template #right-icon>
                  <label class="odere">{{ state.orderInfo.goodsLoadCount }}{{conditions(state.orderInfo.goodsCalcRuleType)}}</label>
                </template>
              </van-cell>
              <van-collapse v-model="activeNames"
                            v-if="state.isUserType == 'User'">
                <van-collapse-item name="1"
                                   title="总金额"
                                   :value="maixun(decimal(state.orderInfo.totalAmount))"
                                   :disabled="state.closetotalAmount"
                                   :is-link="state.islinktotalAmount">
                  <div>
                    <van-row>
                      <van-col span="1"></van-col>
                      <van-col span="7">计价类型</van-col>
                      <van-col span="16"
                               class="Columnright">{{condition(state.OrderFreightCal.calcRuleType)}}（{{conditions(state.OrderFreightCal.calcRuleType)}}）</van-col>
                      <van-col span=""></van-col>
                    </van-row>
                  </div>
                  <div>
                    <van-row>
                      <van-col span="1"></van-col>
                      <van-col span="7">装载运费</van-col>
                      <van-col span="15"
                               class="Columnright">{{maixun(decimal(state.OrderFreightCal.loadCountFreight))}}</van-col>
                      <van-col span="1"></van-col>
                    </van-row>
                  </div>
                  <div>
                    <van-row>
                      <van-col span="1"></van-col>
                      <van-col span="7">基础运费</van-col>
                      <van-col span="15"
                               class="Columnright">{{maixun(decimal(state.OrderFreightCal.baseFreight))}}</van-col>
                      <van-col span="1"></van-col>
                    </van-row>
                  </div>
                  <div>
                    <van-row>
                      <van-col span="1"></van-col>
                      <van-col span="7">距离运费</van-col>
                      <van-col span="15"
                               class="Columnright">{{maixun(decimal(state.OrderFreightCal.distanceFreight))}}</van-col>
                      <van-col span="1"></van-col>
                    </van-row>
                  </div>
                  <div v-if="state.userTipsAmount">
                    <van-row>
                      <van-col span="1"></van-col>
                      <van-col span="7">感谢费</van-col>
                      <van-col span="15"
                               class="Columnright">{{maixun(decimal(state.OrderFreightCal.userTipsAmount))}}</van-col>
                      <van-col span="1"></van-col>
                    </van-row>
                  </div>
                </van-collapse-item>
              </van-collapse>
              <van-collapse v-model="activeNames"
                            v-if="state.isUserType == 'User'">
                <van-collapse-item name="0"
                                   title="付款金额"
                                   :value="maixun(decimal(state.orderInfo.amountPayable))"
                                   :disabled="state.closeamountPayable"
                                   :is-link="state.islinkamountPayable">
                  <div v-if="state.systemDiscountAmount">
                    <van-row>
                      <van-col span="1"></van-col>
                      <van-col span="7">系统优惠</van-col>
                      <van-col span="15"
                               class="Columnright">{{maixun(decimal(state.orderInfo.systemDiscountAmount))}}</van-col>
                      <van-col span="1"></van-col>
                    </van-row>
                  </div>
                  <div v-if="state.couponDiscountAmount">
                    <van-row>
                      <van-col span="1"></van-col>
                      <van-col span="7">优惠券</van-col>
                      <van-col span="15"
                               class="Columnright">{{maixun(decimal(state.orderInfo.couponDiscountAmount))}}</van-col>
                      <van-col span="1"></van-col>
                    </van-row>
                  </div>
                  <div v-if="state.redPackDiscountAmount">
                    <van-row>
                      <van-col span="1"></van-col>
                      <van-col span="7">红包抵扣</van-col>
                      <van-col span="15"
                               class="Columnright">{{maixun(decimal(state.orderInfo.redPackDiscountAmount))}}</van-col>
                      <van-col span="1"></van-col>
                    </van-row>
                  </div>
                  <div>
                    <van-row>
                      <van-col span="1"></van-col>
                      <van-col span="7">已优惠</van-col>
                      <van-col span="15"
                               class="Columnright">{{maixun(decimal(state.Discounted))}}</van-col>
                      <van-col span="1"></van-col>
                    </van-row>
                  </div>
                </van-collapse-item>
              </van-collapse>
              <van-cell title="收入金额"
                        :value="maixun(state.orderInfo.settleTotalAmount)"
                        v-if="state.isUserType == 'Driver'" />
              <van-cell title="下单时间">
                <template #right-icon>
                  <label class="odere"> {{ state.orderInfo.orderDate }}</label>
                </template>
              </van-cell>
              <van-cell title="预计送达时间"
                        v-if="state.isShowDriverinfo == true">
                <template #right-icon>
                  <label class="odere"> {{ state.orderInfo.planDeliveredOrderDate }}</label>
                </template>
              </van-cell>
              <van-cell title="送达时间"
                        v-if="state.isShowDate">
                <template #right-icon>
                  <label class="odere"> {{ state.orderInfo.deliveredOrderDate }}</label>
                </template>
              </van-cell>
            </van-cell-group>
          </div>
          <van-popup v-model:show="showOrderImg"
                     closeable
                     position="bottom"
                     :style="{ height: '70%' }">
            <van-row>
              <samp class="img-order">
                <h3>送达图片</h3>
              </samp>
            </van-row>
            <div class="img-row-order">
              <div class="img-image-order"
                   v-for="(ImgItem,index) in state.ImgSegmentation"
                   :key="index">
                <van-image width="85%"
                           height="90"
                           fit="cover"
                           @click="ImgEnlargement(ImgItem)"
                           :src="ImgItem" />
              </div>
            </div>
          </van-popup>
          <!--  配送信息 -->
          <div class="orderMsg">
            <van-row>
              <van-col span="1"></van-col>
              <van-col span="15">
                <h5>配送信息</h5>
              </van-col>
              <van-col span="8"
                       class="manet"
                       v-if="state.Delivery">
                <van-button type="primary"
                            size="normal"
                            @click="loading">开始送货</van-button>
              </van-col>
              <van-col span="8"
                       class="manet"
                       v-if="state.DeliveryConfirmPic">
                <van-button type="primary"
                            size="normal"
                            @click="loadingConfirmPickUpOrder">开始取货</van-button>
              </van-col>
            </van-row>
            <van-cell-group v-for="(item,index) in state.wayCoordinates"
                            :key="index">
              <van-divider :style="{ color: '#808080', borderColor: '#808080', padding: '0 16px' }">
                收货信息{{index+1}}
              </van-divider>
              <van-cell title="收货联系人"
                        v-if="state.cancellationOrder">
                <template #right-icon>
                  <a :href="'tel:' + item.consigneePhone">
                    <div class="driverMsg_label orderMsgFloat_left">
                      <van-icon name="phone-o"
                                size="20"
                                color="#02b6fd" />
                    </div>
                    <div class="driverMsg_label orderMsgFloat_left"
                         style="color: #02b6fd">
                      {{ item.consigneeName }}
                    </div>
                  </a>
                </template>
              </van-cell>
              <van-cell title="物品名称"
                        :value="item.goodsInfo" />
              <van-cell title="配送状态"
                        v-if="state.isUserType == 'User'">
                <template #right-icon>
                  {{orderStatustonn(item.orderStatus)}}
                </template>
              </van-cell>
              <van-cell title="送达图片"
                        v-if="state.IsDeliveryPicture">
                <template #right-icon>
                  <van-button type="primary"
                              class="hosertywe  junbsgfe"
                              size="small"
                              @click="imgOrder(item.deliveryNo)">送达图片</van-button>
                </template>
              </van-cell>
              <van-cell title="配送状态"
                        v-if="state.Distribution">
                <template #right-icon>
                  <label>{{orderStatustonn(item.orderStatus)}}</label>
                </template>
              </van-cell>
              <van-cell title="送达时间"
                        v-if="item.orderStatus==OrderStatusEnum.Delivered ||item.orderStatus==OrderStatusEnum.Completed ? true:false">
                <template #right-icon>
                  <label class="odere"> {{ item.deliveredOrderDate }}</label>
                </template>
              </van-cell>
              <label v-if="state.isUserType == 'Driver'">
                <van-cell title=""
                          v-if="item.orderStatusDisplaytow">
                  <template #right-icon>
                    <van-button type="primary"
                                class="hosertywe  junbsgfe"
                                size="normal"
                                v-permission="{'DeliveredOrderImg':'DeliveredOrderImg'}"
                                @click="oderstaadd(item.deliveryNo,item.endCoordinate)">已送达</van-button>
                  </template>
                </van-cell>
              </label>
              <van-cell title="备注"
                        :value="item.userRemark" />
              <van-cell title="收货地址"
                        :value="item.endDetailAddress" />
              <p v-if="state.isUserType == 'Driver'">
                <van-row v-if="item.SubNavigation">
                  <van-col span="18"></van-col>
                  <van-col span="6"
                           v-if="state.cancellationOrder"
                           class="hosertywe">
                    <van-button type="primary"
                                size="normal"
                                @click="Navigation(index)">
                      <van-icon name="logistics"
                                v-permission="{'MapDownload':'MapDownload'}" />导航
                    </van-button>
                  </van-col>
                </van-row>
              </p>
            </van-cell-group>
            <van-action-sheet v-model:show="show"
                              cancel-text="取消"
                              description="地图选择"
                              :actions="actions"
                              @select="onSelect" />
          </div>
        </div>
        <div class="pinget">
          <van-button v-permission="{'Cancel':'Cancel'}"
                      v-if="state.isShowevaluationBtn"
                      type="primary"
                      class="btnBottom"
                      block
                      @click="SetCancelEvaluationRouter(state.orderInfo.orderNo)">{{ state.bottom }}</van-button>
          <van-button v-if="state.isShoweva"
                      type="warning"
                      @click="UserdeFineduser(state.orderInfo.orderNo)"
                      class="btnBottom"
                      block>确定送达</van-button>
          <van-button v-if="state.ConfirmPic"
                      type="warning"
                      @click="ConfirmPickUpOrder(state.orderInfo.orderNo)"
                      class="btnBottom"
                      block>确认取货</van-button>
        </div>
      </div>
    </div>
    <van-popup v-model:show="showINput"
               round
               close-icon-position="top-left"
               closeable
               :style="{ height: '130px', width: '95%' }"
               :close-on-click-overlay="false"
               class="popupover">
      <van-row>
        <samp class="paypassdw">
          <h3>支付密码</h3>
        </samp>
      </van-row>
      <van-password-input v-if="state.Inputbox"
                          :value="password"
                          class="passwordbox"
                          :gutter="2"
                          :focused="showKeyboard"
                          @focus="showKeyboard = true" />
    </van-popup>
    <van-number-keyboard class="numbervan"
                         v-model="password"
                         :show="showKeyboard"
                         @blur="showKeyboard = false" />
  </div>
</template>
<script>
import { reactive, onMounted, ref, watch } from "vue";
import { OrderType, OrderStatusEnum } from "@/Enum/enumeration.js"
import { useRouter } from "vue-router";
import { userOrderDetails, UpdateUserConfirmOrder, GetDriverLocation, GetOrderFreightCal, OrderInfoGetDriver, DriverArrivePickUpOrder, UpdateUserConfirmPickUpOrder } from "@/serviceApi/Order";
import { returnFloat } from "@/commonContent/js/comm";
import { Toast, ImagePreview, Dialog } from "vant";
import { GCJ02ToBD09 } from "@/commonContent/js/jquery.map ";
import { BackOff } from "@/commonContent/js/comm.js";
import { getCookie } from "@/commonContent/js/Cookie"
import { getUrlParam, IsNullOrEmpty } from "@/commonContent/js/comm";
import { DriverDeliveringOrder, Delivered } from "@/serviceApi/Order";
import { passwordMd5 } from '@/utils/password.js';
import { GetDistance } from "@/commonContent/js/jquery.map .js"
import carimg from "@/static/img/map/car.png";
import stanet from "@/static/img/map/start.png";
import endnet from "@/static/img/map/end.png";
import tuji from "@/static/img/map/midway.png";
import Tips from "@/static/img/Tips/Tips.png";
import AMap from 'AMap' // 引入高德地图
import { gcj02towgs84 } from "@/commonContent/js/jquery.map ";
export default {
  setup () {
    const router = useRouter();
    const show = ref(false);
    const shows = ref(false);
    const wxshow = ref(false);
    const password = ref("");
    const showINput = ref(false);
    const showKeyboard = ref(false);
    const XerPUrl = ref(false);
    const activeNames = ref(['2']);
    const showOrderImg = ref(false);
    const state = reactive({
      ConfirmPic: false,
      DeliveryConfirmPic: false,
      src: "",
      XerPUr: "",
      orderInfo: {},
      closetotalAmount: false,
      closeamountPayable: false,
      islinkamountPayable: true,
      islinktotalAmount: true,
      Discount: false,
      Discounted: "",
      gaodeIcon: [],
      cancellationOrder: true,
      redPackDiscountAmount: false,
      couponDiscountAmount: false,
      systemDiscountAmount: false,
      userTipsAmount: false,
      Distribution: false,
      endCoordinate: '',
      coordinate: '',
      orderCompletion: [],
      Inputbox: true,
      Suborderstatus: false,
      IsDeliveryPicture: false,
      isShowevaluationBtn: false,
      isShowDate: false,
      orderStatus: false,
      isShoweva: false,
      OrderFreightCal: {},
      carioc: "",
      orderStatusert: "",
      timet: "",
      bottom: "评价",
      wayCoordinates: [],
      submitDisabled: [],
      Endname: '',
      isShowDriverinfo: true,
      isUserType: "",
      ContactUser: false,
      Delivery: false,
      showPicker: false,
      driverPhone: "",
      startCoordinate: '',
      ImgSegmentation: [],
      Tips: Tips,
      lastLocationCoordinate: "",
      position: {
        Start: {
          AddressName: "",
          lat: "",
          lng: "",
        },
        End: {
          ledAddress: "",
          lat: "",
          lng: "",
        },
      },
      UserChat: false,
      isKsyMap: true,//地图展示
      isGaMap: true,//地图展示
    });
    const orderStatustonn = (orderStatus) => {
      var text = "";
      switch (orderStatus) {
        case 0:
          text = "未知";
          break;
        case 1:
          text = "待支付";
          break;
        case 2:
          text = "待接单";
          break;
        case 3:
          text = "已接单";
          break;
        case 11:
          text = "到达取货";
          break;
        case 12:
          text = "确认取货";
          break;
        case 21:
          text = "送货中";
          break;
        case 22:
          text = "已送达";
          break;
        case 99:
          text = "已完成";
          break;
        case 89:
          text = "已取消";
          break;
      }
      return text;
    };
    const SetCancelEvaluationRouter = (orderNo) => {
      const userType = getUrlParam("userType");
      if (state.bottom == "取消订单" || state.bottom == "取消接单") {
        if (userType == "User") {
          ToView({
            name: "Cancel",
            query: { orderNo: orderNo, userType: state.isUserType },
          });
        }
        if (userType == "Driver") {
          ToView({
            name: "Cancel",
            query: { orderNo: orderNo, userType: state.isUserType, DriverOrderNo: state.orderInfo.driverOrderNo },
          });
        }
      } else if (state.bottom == "评价") {
        ToView({
          name: "Evaluation",

          query: { orderNo: orderNo, userType: state.isUserType },
        });
      }
    };
    const decimal = (val) => {
      if (val > 0) {
        return val = "" + returnFloat(val)
      }
      else {
        return returnFloat(val)
      }
    };
    let map;
    let drivingRoute;
    const onLoad = (address, startInformation, routeInformation) => {
      address = gcj02towgs84(address.lng, address.lat);
      map = new T.Map("mapDiv");
      map.centerAndZoom(new T.LngLat(address[0], address[1]), 13);
      var config = {
        policy: 0, //驾车策略
        onSearchComplete: searchResult, //检索完成后的回调函数
      };
      drivingRoute = new T.DrivingRoute(map, config);
      map.clearOverLays();
      let startLngLat = new T.LngLat(address[0], address[1]);
      let routeIn = [];
      let userSplit = startInformation.split(',');
      if (routeInformation[routeInformation.length - 1].endCoordinate != 0) {
        routeIn = routeInformation[routeInformation.length - 1].endCoordinate.split(',');
      } else {
        routeIn = userSplit
      }
      routeIn = gcj02towgs84(parseFloat(routeIn[0]), parseFloat(routeIn[1]));
      let endLngLat = new T.LngLat(routeIn[0], routeIn[1]);
      let startingData = [];
      userSplit = gcj02towgs84(parseFloat(userSplit[0]), parseFloat(userSplit[1]));
      startingData.push(new T.LngLat(userSplit[0], userSplit[1]));
      if ((routeInformation.length - 1) > 0) {
        for (let index = 0; index < routeInformation.length - 1; index++) {
          if (routeInformation[index].endCoordinate != 0) {
            let user = routeInformation[index].endCoordinate;
            let userData = user.split(',')
            userData = gcj02towgs84(parseFloat(userData[0]), parseFloat(userData[1]));
            startingData.push(new T.LngLat(userData[0], userData[1]))
          } else {
            startingData.push(new T.LngLat(userSplit[0], userSplit[1]))
          }
        }
      }
      //驾车路线搜索
      drivingRoute.search(startLngLat, endLngLat, startingData);
    }
    const createRoute = (lnglats, lineColor) => {
      //创建线对象
      let line = new T.Polyline(lnglats, {
        color: "#2C64A7",
        weight: 5,
        opacity: 0.9,
      });
      //向地图上添加线
      map.addOverLay(line);
    }
    //添加起始点
    const createStartMarker = (result) => {
      var startMarker = new T.Marker(result.getStart(), {
        icon: new T.Icon({
          iconUrl: carimg,
          iconSize: new T.Point(25, 34),
          iconAnchor: new T.Point(15, 35),
        }),
      });
      map.addOverLay(startMarker);
      var endMarker = new T.Marker(result.getEnd(), {
        icon: new T.Icon({
          iconUrl: endnet,
          iconSize: new T.Point(25, 34),
          iconAnchor: new T.Point(15, 35),
        }),
      });
      map.addOverLay(endMarker);
      let routeIfo = result.results.mid.split(";");
      for (let index = 0; index < routeIfo.length; index++) {
        if (index == 0) {
          let mid = routeIfo[index].split(',');
          let Marker = new T.LngLat(mid[0], mid[1]);
          var midMarker1 = new T.Marker(Marker, {
            icon: new T.Icon({
              iconUrl: stanet,
              iconSize: new T.Point(25, 34),
              iconAnchor: new T.Point(15, 35),
            })
          });
          map.addOverLay(midMarker1);
        } else {
          let mid = routeIfo[index].split(',');
          let Marker = new T.LngLat(mid[0], mid[1]);
          var midMarker2 = new T.Marker(Marker, {
            icon: new T.Icon({
              iconUrl: tuji,
              iconSize: new T.Point(25, 34),
              iconAnchor: new T.Point(15, 35),
            }),
          });
          map.addOverLay(midMarker2);
        }
      }
    }
    const searchResult = (result) => {
      //添加起始点
      createStartMarker(result);
      let obj = result;
      //获取方案个数
      var routes = result.getNumPlans();
      for (var i = 0; i < routes; i++) {
        //获得单条驾车方案结果对象
        var plan = result.getPlan(i);
        //显示驾车线路
        createRoute(plan.getPath());
        //显示最佳级别
        map.setViewport(plan.getPath());
      }
    }
    const startOnLoad = (address, routeInformation) => {
      address = gcj02towgs84(address.lng, address.lat);
      map = new T.Map("mapDiv");
      map.centerAndZoom(new T.LngLat(address[0], address[1]), 13);
      var config = {
        policy: 0, //驾车策略
        onSearchComplete: startSearchResult, //检索完成后的回调函数
      };
      drivingRoute = new T.DrivingRoute(map, config);
      map.clearOverLays();
      let startLngLat = new T.LngLat(address[0], address[1]);
      let routeIn = routeInformation[routeInformation.length - 1].endCoordinate.split(',');
      if (!(routeIn[0] && routeIn[1])) {
        routeIn[0] = address.lng
        routeIn[1] = address.lat
      }
      routeIn = gcj02towgs84(parseFloat(routeIn[0]), parseFloat(routeIn[1]));
      let endLngLat = new T.LngLat(routeIn[0], routeIn[1]);
      let startingData = [];
      if ((routeInformation.length - 1) > 0) {
        for (let index = 0; index < routeInformation.length - 1; index++) {
          if (routeInformation[index].endCoordinate != 0) {
            let user = routeInformation[index].endCoordinate;
            let userData = user.split(',')
            userData = gcj02towgs84(parseFloat(userData[0]), parseFloat(userData[1]));
            startingData.push(new T.LngLat(userData[0], userData[1]))
          }
          else {
            startingData.push(new T.LngLat(address[0], address[1]))
          }
        }
      } else {
        startingData = null;
      }
      //驾车路线搜索
      drivingRoute.search(startLngLat, endLngLat, startingData);
    }
    //添加起始点
    const startCreateStartMarker = (result) => {
      var startMarker = new T.Marker(result.getStart(), {
        icon: new T.Icon({
          iconUrl: stanet,
          iconSize: new T.Point(25, 34),
          iconAnchor: new T.Point(15, 35),
        }),
      });
      map.addOverLay(startMarker);
      var endMarker = new T.Marker(result.getEnd(), {
        icon: new T.Icon({
          iconUrl: endnet,
          iconSize: new T.Point(25, 34),
          iconAnchor: new T.Point(15, 35),
        }),
      });
      map.addOverLay(endMarker);
      if (result.results.mid) {
        let routeIfo = result.results.mid.split(";");
        for (let index = 0; index < routeIfo.length; index++) {
          let mid = routeIfo[index].split(',');
          let Marker = new T.LngLat(mid[0], mid[1]);
          var midMarker2 = new T.Marker(Marker, {
            icon: new T.Icon({
              iconUrl: tuji,
              iconSize: new T.Point(25, 34),
              iconAnchor: new T.Point(15, 35),
            }),
          });
          map.addOverLay(midMarker2);
        }
      }
    }
    const startSearchResult = (result) => {
      //添加起始点
      startCreateStartMarker(result);
      let obj = result;
      //获取方案个数
      var routes = result.getNumPlans();
      for (var i = 0; i < routes; i++) {
        //获得单条驾车方案结果对象
        var plan = result.getPlan(i);
        //显示驾车线路
        createRoute(plan.getPath());
        //显示最佳级别
        map.setViewport(plan.getPath());
      }
    }
    onMounted(() => {
      document.getElementById("app").scrollIntoView();
      const orderNo = getUrlParam("orderNo");
      const userType = getUrlParam("userType");
      const orderStatus = getUrlParam("orderStatus");
      const DriverOrderNo = getUrlParam("DriverOrderNo");
      state.orderStatusert = orderStatus
      state.isUserType = userType;
      if (userType == "User") {
        let params = {
          orderNo: orderNo
        }
        userOrderDetails(params).then((res) => {
          if (res.code == 1) {
            if (res.data.orderStatus == OrderStatusEnum.Received && state.isUserType == "Driver") {
              Date.prototype.Format = function (fmt) {
                var o = {
                  "M+": this.getMonth() + 1, // 月份
                  "d+": this.getDate(), // 日
                  "h+": this.getHours(), // 小时
                  "m+": this.getMinutes(), // 分
                  "s+": this.getSeconds(), // 秒
                  "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
                  "S": this.getMilliseconds() // 毫秒
                };
                if (/(y+)/.test(fmt))
                  fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
                for (var k in o)
                  if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                return fmt;
              }
              var when = getSpeicalTime();
              when = when.Format("yyyy-MM-dd hh:mm:ss");
              if (res.data.orderType == OrderType.StoreDriver) {
                state.isShowevaluationBtn = false;
              } else {
                if (new Date(res.data.receivedOrderDate) > new Date(when)) {
                  state.isShowevaluationBtn = true;
                  state.bottom = "取消接单";
                }
              }
            }
            if (!(res.data.orderStatus == OrderStatusEnum.Unpaid || res.data.orderStatus == OrderStatusEnum.Cancelled || res.data.orderStatus == OrderStatusEnum.Waiting)) {
              state.UserChat = true;
            }
            if (res.data.orderStatus == OrderStatusEnum.Delivered) {
              state.isShowDate = true
              if (res.data.isUserEvaluation && state.isUserType == "Driver") {
                state.isShowevaluationBtn = false;
              } else if (res.data.isDriverEvaluation && state.isUserType == "User") {
                state.isShowevaluationBtn = false;
              }
              else if (!res.data.isUserConfirm && state.isUserType == "User") {
                state.isShowevaluationBtn = false;
                state.isShoweva = true;
              }
              else {
                state.isShowevaluationBtn = false;
                state.isShoweva = false;
              }
            }
            else if (res.data.orderStatus == OrderStatusEnum.Completed) {
              state.isShowDate = true;
              state.isShowDriverinfo = true;
              if (state.isUserType == "User" && !res.data.isUserEvaluation) {
                state.isShowevaluationBtn = true;
                state.bottom = "评价";
              }
              else if (state.isUserType == "Driver" && !res.data.isDriverEvaluation) {
                state.isShowevaluationBtn = true;
                state.bottom = "评价";
              }
              else {
                state.isShowevaluationBtn = false;
              }
            }
            else if (res.data.orderStatus == OrderStatusEnum.Unpaid || res.data.orderStatus == OrderStatusEnum.Cancelled || res.data.orderStatus == OrderStatusEnum.Waiting) {
              state.isShowDriverinfo = false;
              if (res.data.cancelStatus == 2 || !IsNullOrEmpty(res.data.cancelReason)) {
                state.isShowevaluationBtn = false;
              }
              else if (state.isUserType == "User" && res.data.orderStatus == OrderStatusEnum.Cancelled) {
                state.isShowevaluationBtn = false;

              }
              else {
                if (res.data.orderType == 2 || res.data.orderType == 3) {
                  state.isShowevaluationBtn = false;
                } else {
                  state.isShowevaluationBtn = true;
                  state.bottom = "取消订单";
                }
              }
            }
            else {
              state.isShowDate = false;
              state.isShowDriverinfo = true;
            }
            if (res.data.orderStatus == OrderStatusEnum.ArrivePickUp) {
              state.ConfirmPic = true;
            }
            state.orderInfo = res.data;
            //订单金额显示判断
            systemDiscountAmount();
            redPackDiscountAmount();
            userTipsAmount();
            couponDiscountAmount();
            if (state.orderInfo.systemDiscountAmount != 0 || state.orderInfo.couponDiscountAmount != 0 || state.orderInfo.redPackDiscountAmount != 0) {
              state.closeamountPayable = false;
              state.Discounted = state.orderInfo.totalAmount - state.orderInfo.amountPayable;
            }
            else {
              state.islinkamountPayable = false;
              state.closeamountPayable = true;
            }
            if (state.orderInfo.orderStatus == OrderStatusEnum.Unpaid && userType == "User") {
              state.orderInfo.Paymentdisplay = true
            }
            else {
              state.orderInfo.Paymentdisplay = false
            }
            state.wayCoordinates = res.data.wayCoordinates
            for (let index = 0; index < state.wayCoordinates.length; index++) {
              state.wayCoordinates[index].orderStatusDisplay = true;
              state.wayCoordinates[index].SubNavigation = true;
              state.wayCoordinates[index].orderStatusDisplaytow = false;
              if (state.wayCoordinates[index].orderStatus == OrderStatusEnum.Delivering) {
                state.wayCoordinates[index].orderStatusDisplaytow = true
                state.wayCoordinates[index].orderStatusDisplay = false
              }
            }
            state.startCoordinate = res.data.startCoordinate
            if (process.env.VUE_APP_SkyMap == "true" && navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0) {
              /* 天地图 */
              state.isGaMap = false;
              if (res.data.orderStatus == OrderStatusEnum.Received || res.data.orderStatus == OrderStatusEnum.Delivering || res.data.orderStatus == OrderStatusEnum.ReturnBack || res.data.orderStatus == OrderStatusEnum.ArrivePickUp || res.data.orderStatus == OrderStatusEnum.ConfirmPickUp) {
                GetDriverLocation(res.data.orderNo).then((resde) => {
                  if (resde.code == 1) {
                    if (res.data.lastLocationCoordinate == "") {
                      state.lastLocationCoordinate = state.startCoordinate.split(',');
                    }
                    state.lastLocationCoordinate = resde.data.lastLocationCoordinate.split(',')
                    let stra = {};
                    stra.endCoordinate = res.data.wayCoordinates;
                    let address = {
                      lng: parseFloat(state.lastLocationCoordinate[0]),
                      lat: parseFloat(state.lastLocationCoordinate[1])
                    }
                    onLoad(address, state.startCoordinate, stra.endCoordinate);
                  }
                })
              } else {
                let endCoordinate = res.data.wayCoordinates;
                let stra = state.startCoordinate.split(',');
                let address = {
                  lng: parseFloat(stra[0]),
                  lat: parseFloat(stra[1])
                }
                startOnLoad(address, endCoordinate);
              }
            } else {
              /* 高德地图 */
              state.isKsyMap = false;
              let map = new AMap.Map('containerrete', {
                resizeEnable: true,
                zoom: 13
              })
              AMap.service('AMap.Driving', function () {//回调函数
                var driving = new AMap.Driving({
                  map: map,
                });
                if (res.data.orderStatus == OrderStatusEnum.Received || res.data.orderStatus == OrderStatusEnum.Delivering || res.data.orderStatus == OrderStatusEnum.ReturnBack || res.data.orderStatus == OrderStatusEnum.ArrivePickUp || res.data.orderStatus == OrderStatusEnum.ConfirmPickUp) {
                  GetDriverLocation(res.data.orderNo).then((resde) => {
                    if (resde.code == 1) {
                      if (res.data.lastLocationCoordinate == "") {
                        state.lastLocationCoordinate = state.startCoordinate.split(',');
                      }
                      state.lastLocationCoordinate = resde.data.lastLocationCoordinate.split(',')
                      let stra = state.startCoordinate.split(',');
                      stra.endCoordinate = res.data.wayCoordinates
                      let nuber = []
                      for (let index = 0; index < stra.endCoordinate.length - 1; index++) {
                        if (stra.endCoordinate[index].orderStatus == OrderStatusEnum.Cancelled) {
                          /*  continue  */
                        }
                        if (stra.endCoordinate[index].endCoordinate) {
                          let user = stra.endCoordinate[index].endCoordinate
                          let ender = user.split(',')
                          nuber.push(new AMap.LngLat(ender[0], ender[1]))
                        } else {
                          nuber.push(new AMap.LngLat(stra[0], stra[1]))
                        }
                      }
                      let end = stra.endCoordinate[stra.endCoordinate.length - 1].endCoordinate.split(',')
                      if (!(end[0] && end[1])) {
                        end[0] = stra[0]
                        end[1] = stra[1]
                      }
                      nuber.unshift(new AMap.LngLat(stra[0], stra[1]))
                      driving.search(new AMap.LngLat(state.lastLocationCoordinate[0], state.lastLocationCoordinate[1]), new AMap.LngLat(end[0], end[1]), {
                        waypoints: nuber
                      })
                      var startIcon = new AMap.Icon({
                        // 图标尺寸
                        size: new AMap.Size(25, 34),
                        // 图标的取图地址
                        image: carimg,
                        // 图标所用图片大小
                        imageSize: new AMap.Size(44, 40),
                        // 图标取图偏移量
                        imageOffset: new AMap.Pixel(-9, -3)
                      });
                      var startMarker = new AMap.Marker({
                        position: new AMap.LngLat(state.lastLocationCoordinate[0], state.lastLocationCoordinate[1]),
                        icon: startIcon,
                        offset: new AMap.Pixel(-10, -35),
                        zIndex: 9999
                      });
                      var afterIcon = new AMap.Icon({
                        // 图标尺寸
                        size: new AMap.Size(40, 45),
                        // 图标的取图地址
                        image: stanet,
                        // 图标所用图片大小
                        imageSize: new AMap.Size(28, 38),
                        // 图标取图偏移量
                        imageOffset: new AMap.Pixel(0.4, -0.5)
                      });
                      var afterIcones = new AMap.Icon({
                        // 图标尺寸
                        size: new AMap.Size(40, 45),
                        // 图标的取图地址
                        image: tuji,
                        // 图标所用图片大小
                        imageSize: new AMap.Size(28, 38),
                        // 图标取图偏移量
                        imageOffset: new AMap.Pixel(0.4, -0.5)
                      });
                      var safterMarker = new AMap.Marker({
                        position: new AMap.LngLat(stra[0], stra[1]),
                        icon: afterIcon,
                        offset: new AMap.Pixel(-10, -35),
                        zIndex: 9999
                      });
                      for (let index = 0; index < nuber.length; index++) {
                        if (nuber[index] != nuber[0]) {
                          var safterMarkeres = new AMap.Marker({
                            position: new AMap.LngLat(nuber[index].lng, nuber[index].lat),
                            icon: afterIcones,
                            offset: new AMap.Pixel(-10, -35),
                            zIndex: 9999
                          });
                          map.add([safterMarkeres]);
                        }
                      }
                      var endIcon = new AMap.Icon({
                        // 图标尺寸
                        size: new AMap.Size(40, 45),
                        // 图标的取图地址
                        image: endnet,
                        // 图标所用图片大小
                        imageSize: new AMap.Size(28, 38),
                        // 图标取图偏移量
                        imageOffset: new AMap.Pixel(0.4, -0.5)
                      });
                      var endMarker = new AMap.Marker({
                        position: new AMap.LngLat(end[0], end[1]),
                        icon: endIcon,
                        offset: new AMap.Pixel(-15, -30),
                        zIndex: 9999

                      });
                      map.add([startMarker, safterMarker, endMarker]);
                      state.gaodeIcon.push(startMarker)
                    }
                    else {
                      Toast.fail(resde.msg)
                    }
                  });
                  state.timet = setInterval(() => {
                    GetDriverLocation(res.data.orderNo).then((resde) => {
                      if (resde.code == 1) {
                        state.lastLocationCoordinate = resde.data.lastLocationCoordinate.split(',')
                        let stra = state.startCoordinate.split(',');
                        stra.endCoordinate = res.data.wayCoordinates
                        let nuber = []
                        for (let index = 0; index < stra.endCoordinate.length - 1; index++) {
                          if (stra.endCoordinate[index].orderStatus == OrderStatusEnum.Cancelled) {
                            /*  continue  */
                          }
                          if (stra.endCoordinate[index].endCoordinate) {
                            let user = stra.endCoordinate[index].endCoordinate
                            let ender = user.split(',')
                            nuber.push(new AMap.LngLat(ender[0], ender[1]))
                          } else {
                            nuber.push(new AMap.LngLat(stra[0], stra[1]))
                          }
                        }

                        let end = stra.endCoordinate[stra.endCoordinate.length - 1].endCoordinate.split(',')
                        nuber.unshift(new AMap.LngLat(stra[0], stra[1]))
                        if (!(end[0] && end[1])) {
                          end[0] = stra[0]
                          end[1] = stra[1]
                        }
                        driving.search(new AMap.LngLat(state.lastLocationCoordinate[0], state.lastLocationCoordinate[1]), new AMap.LngLat(end[0], end[1]), {
                          waypoints: nuber
                        })

                        var startIcon = new AMap.Icon({
                          // 图标尺寸
                          size: new AMap.Size(25, 34),
                          // 图标的取图地址
                          image: carimg,
                          // 图标所用图片大小
                          imageSize: new AMap.Size(44, 40),
                          // 图标取图偏移量
                          imageOffset: new AMap.Pixel(-9, -3)
                        });
                        var startMarker = new AMap.Marker({
                          position: new AMap.LngLat(state.lastLocationCoordinate[0], state.lastLocationCoordinate[1]),
                          icon: startIcon,
                          offset: new AMap.Pixel(-10, -35),
                          zIndex: 9999

                        });
                        var afterIcon = new AMap.Icon({
                          // 图标尺寸
                          size: new AMap.Size(40, 45),
                          // 图标的取图地址
                          image: stanet,
                          // 图标所用图片大小
                          imageSize: new AMap.Size(28, 38),
                          // 图标取图偏移量
                          imageOffset: new AMap.Pixel(0.4, -0.5)
                        });
                        var safterMarker = new AMap.Marker({
                          position: new AMap.LngLat(stra[0], stra[1]),
                          icon: afterIcon,
                          offset: new AMap.Pixel(-10, -35),
                          zIndex: 9999

                        });
                        var endIcon = new AMap.Icon({
                          // 图标尺寸
                          size: new AMap.Size(40, 45),
                          // 图标的取图地址
                          image: endnet,
                          // 图标所用图片大小
                          imageSize: new AMap.Size(28, 38),
                          // 图标取图偏移量
                          imageOffset: new AMap.Pixel(0.4, -0.5)
                        });
                        var endMarker = new AMap.Marker({
                          position: new AMap.LngLat(end[0], end[1]),
                          icon: endIcon,
                          offset: new AMap.Pixel(-15, -30),
                          zIndex: 9999

                        });
                        let mapts = [startMarker, safterMarker, endMarker]
                        map.add(mapts);
                        state.gaodeIcon[0].setMap(null);
                        state.gaodeIcon.push(startMarker);
                        state.gaodeIcon.shift();
                      }
                      else {

                      }
                    });

                  }, process.env.VUE_APP_DELIVERY_LOCATION_TIME)
                }
                else {
                  let stra = state.startCoordinate.split(',');
                  stra.endCoordinate = res.data.wayCoordinates
                  let nuber = []
                  for (let index = 0; index < stra.endCoordinate.length - 1; index++) {
                    if (stra.endCoordinate[index].orderStatus == OrderStatusEnum.Cancelled) {
                      /*  continue */
                    }
                    if (stra.endCoordinate[index].endCoordinate) {
                      let user = stra.endCoordinate[index].endCoordinate
                      let ender = user.split(',')
                      nuber.push(new AMap.LngLat(ender[0], ender[1]))
                    } else {
                      nuber.push(new AMap.LngLat(stra[0], stra[1]))
                    }
                  }
                  let end = stra.endCoordinate[stra.endCoordinate.length - 1].endCoordinate.split(',')
                  if (!(end[0] && end[1])) {
                    end[0] = stra[0]
                    end[1] = stra[1]
                  }
                  driving.search(new AMap.LngLat(stra[0], stra[1]), new AMap.LngLat(end[0], end[1]), {
                    waypoints: nuber
                  })
                }
              })
            }
            //计价显示隐藏
            GetOrderFreightCal(orderNo).then((res) => {
              if (res.code == 1) {
                state.OrderFreightCal = res.data;
              }
              else {
                state.islinkamountPayable = false;
                state.islinktotalAmount = false;
                state.closetotalAmount = true;
                state.closeamountPayable = true;
              }
            })
          } else {
            Toast.fail(res.msg);
          }
        })

      }
      if (userType == "Driver") {
        state.ContactUser = true;
        let params = {
          orderNo: orderNo,
          DriverOrderNo: DriverOrderNo
        }
        OrderInfoGetDriver(params).then((res) => {
          if (res.code == 1) {
            if (res.data.orderStatus != OrderStatusEnum.Cancelled) {
              state.Distribution = true;
            }
            if (res.data.orderStatus == OrderStatusEnum.Received && state.isUserType == "Driver") {
              Date.prototype.Format = function (fmt) { // author: meizz
                var o = {
                  "M+": this.getMonth() + 1, // 月份
                  "d+": this.getDate(), // 日
                  "h+": this.getHours(), // 小时
                  "m+": this.getMinutes(), // 分
                  "s+": this.getSeconds(), // 秒
                  "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
                  "S": this.getMilliseconds() // 毫秒
                };
                if (/(y+)/.test(fmt))
                  fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
                for (var k in o)
                  if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                return fmt;
              }
              var when = getSpeicalTime();
              when = when.Format("yyyy-MM-dd hh:mm:ss");
              if (res.data.orderType == OrderType.StoreDriver) {
                state.isShowevaluationBtn = false;
              } else {
                if (new Date(res.data.receivedOrderDate) > new Date(when)) {
                  state.isShowevaluationBtn = true;
                  state.bottom = "取消接单";
                }
              }
            }
            if (!(res.data.orderStatus == OrderStatusEnum.Unpaid || res.data.orderStatus == OrderStatusEnum.Cancelled || res.data.orderStatus == OrderStatusEnum.Waiting || res.data.orderStatus == OrderStatusEnum.ArrivePickUp || res.data.orderStatus == OrderStatusEnum.ConfirmPickUp)) {
              state.UserChat = true;
            }
          }
          if (res.data.orderStatus >= OrderStatusEnum.Delivered) {
            state.Suborderstatus = false
          } else {
            state.Suborderstatus = true
          }
          if (res.data.orderStatus == OrderStatusEnum.Delivered || res.data.orderStatus == OrderStatusEnum.Completed) {
            state.IsDeliveryPicture = true;
          }
          if (res.data.orderStatus == OrderStatusEnum.Delivered) {
            state.isShowDate = true
            if (res.data.isUserEvaluation && state.isUserType == "Driver") {
              state.isShowevaluationBtn = false;
            } else if (res.data.isDriverEvaluation && state.isUserType == "User") {
              state.isShowevaluationBtn = false;
            }
            else if (!res.data.isUserConfirm && state.isUserType == "User") {
              state.isShowevaluationBtn = false;
              state.isShoweva = true;
            }
            else {
              state.isShowevaluationBtn = false;
              state.isShoweva = false;
            }
          }
          else if (res.data.orderStatus == OrderStatusEnum.Completed) {
            state.isShowDate = true;
            state.isShowDriverinfo = true;
            if (state.isUserType == "User" && !res.data.isUserEvaluation) {
              state.isShowevaluationBtn = true;
              state.bottom = "评价";
            }
            else if (state.isUserType == "Driver" && !res.data.isDriverEvaluation) {
              state.isShowevaluationBtn = true;
              state.bottom = "评价";
            }
            else {
              state.isShowevaluationBtn = false;
            }
          }
          else if (res.data.orderStatus == OrderStatusEnum.Unpaid || res.data.orderStatus == OrderStatusEnum.Cancelled || res.data.orderStatus == OrderStatusEnum.Waiting) {
            state.isShowDriverinfo = false;
            if (res.data.cancelStatus == 2 || !IsNullOrEmpty(res.data.cancelReason)) {
              state.isShowevaluationBtn = false;
            }
            else if (state.isUserType == "User" && res.data.orderStatus == OrderStatusEnum.Cancelled) {
              state.isShowevaluationBtn = false;

            }
            else {
              if (res.data.orderType == 2 || res.data.orderType == 3) {
                state.isShowevaluationBtn = false;
              } else {
                state.isShowevaluationBtn = true;
                state.bottom = "取消订单";
              }
            }
          }
          else {
            state.isShowDate = false;
            state.isShowDriverinfo = true;
          }
          if (res.data.orderStatus == OrderStatusEnum.Cancelled) {
            state.ContactUser = false;
            state.cancellationOrder = false;
          }
          let extendData = res.data.extendData;
          if (extendData) {
            let xer = JSON.parse(extendData).XerpUrl;
            if (xer) {
              state.XerPUr = JSON.parse(extendData).XerpUrl;
              XerPUrl.value = true;
            }
          }
          if (state.isUserType == "Driver" && res.data.orderStatus == OrderStatusEnum.Received) {
            state.DeliveryConfirmPic = true;
          }
          state.orderInfo = res.data;
          //订单金额显示判断
          systemDiscountAmount();
          redPackDiscountAmount();
          userTipsAmount();
          couponDiscountAmount();
          if (state.orderInfo.systemDiscountAmount != 0 || state.orderInfo.couponDiscountAmount != 0 || state.orderInfo.redPackDiscountAmount != 0) {
            state.closeamountPayable = false;
            state.Discounted = state.orderInfo.totalAmount - state.orderInfo.amountPayable;
          }
          else {
            state.islinkamountPayable = false;
            state.closeamountPayable = true;
          }
          if (state.orderInfo.orderStatus == OrderStatusEnum.Unpaid && userType == "User") {
            state.orderInfo.Paymentdisplay = true
          }
          else {
            state.orderInfo.Paymentdisplay = false
          }
          state.wayCoordinates = res.data.wayCoordinates
          if (state.orderInfo.orderStatus > OrderStatusEnum.Waiting && state.orderInfo.orderStatus < OrderStatusEnum.Completed) {
            for (let index = 0; index < state.wayCoordinates.length; index++) {
              state.wayCoordinates[index].orderStatusDisplay = true;
              state.wayCoordinates[index].SubNavigation = true;
              state.wayCoordinates[index].orderStatusDisplaytow = false;
              if (state.wayCoordinates[index].orderStatus == OrderStatusEnum.Delivering) {
                state.wayCoordinates[index].orderStatusDisplaytow = true
                state.wayCoordinates[index].orderStatusDisplay = false
              }
              else if (state.wayCoordinates[index].orderStatus >= OrderStatusEnum.Delivered) {
                state.wayCoordinates[index].SubNavigation = false;
              }
            }
          }

          if (state.isUserType == "Driver" && (state.orderInfo.orderStatus == OrderStatusEnum.ArrivePickUp || state.orderInfo.orderStatus == OrderStatusEnum.ConfirmPickUp)) {
            state.Delivery = true
          }

          state.startCoordinate = res.data.startCoordinate
          if (process.env.VUE_APP_SkyMap == "true" && navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0) {
            /* 天地图 */
            state.isGaMap = false;
            if (res.data.orderStatus == OrderStatusEnum.Received || res.data.orderStatus == OrderStatusEnum.Delivering || res.data.orderStatus == OrderStatusEnum.ReturnBack || res.data.orderStatus == OrderStatusEnum.ConfirmPickUp || res.data.orderStatus == OrderStatusEnum.ArrivePickUp) {
              GetDriverLocation(res.data.orderNo).then((resde) => {
                if (resde.code == 1) {
                  state.lastLocationCoordinate = resde.data.lastLocationCoordinate.split(',')
                  let address = {
                    lng: parseFloat(state.lastLocationCoordinate[0]),
                    lat: parseFloat(state.lastLocationCoordinate[1])
                  }
                  onLoad(address, state.startCoordinate, res.data.wayCoordinates);
                }
              })
            } else {
              let stra = state.startCoordinate.split(',');
              let address = {
                lng: parseFloat(stra[0]),
                lat: parseFloat(stra[1])
              }
              startOnLoad(address, res.data.wayCoordinates);
            }
          } else {
            state.isKsyMap = false;
            let map = new AMap.Map('containerrete', {
              resizeEnable: true,
              zoom: 13
            })
            AMap.service('AMap.Driving', function () {//回调函数
              var driving = new AMap.Driving({
                map: map,
              });
              if (res.data.orderStatus == OrderStatusEnum.Received || res.data.orderStatus == OrderStatusEnum.Delivering || res.data.orderStatus == OrderStatusEnum.ReturnBack || res.data.orderStatus == OrderStatusEnum.ConfirmPickUp || res.data.orderStatus == OrderStatusEnum.ArrivePickUp) {
                GetDriverLocation(res.data.orderNo).then((resde) => {
                  if (resde.code == 1) {
                    if (res.data.lastLocationCoordinate == "") {
                      state.lastLocationCoordinate = state.startCoordinate.split(',');
                    }
                    state.lastLocationCoordinate = resde.data.lastLocationCoordinate.split(',')
                    let stra = state.startCoordinate.split(',');
                    stra.endCoordinate = res.data.wayCoordinates
                    let nuber = []
                    for (let index = 0; index < stra.endCoordinate.length - 1; index++) {
                      if (stra.endCoordinate[index].orderStatus == OrderStatusEnum.Cancelled) {
                        /*  continue  */
                      }
                      if (stra.endCoordinate[index].endCoordinate) {
                        let user = stra.endCoordinate[index].endCoordinate
                        let ender = user.split(',')
                        nuber.push(new AMap.LngLat(ender[0], ender[1]))
                      } else {
                        nuber.push(new AMap.LngLat(stra[0], stra[1]))
                      }
                    }

                    let end = stra.endCoordinate[stra.endCoordinate.length - 1].endCoordinate.split(',')
                    nuber.unshift(new AMap.LngLat(stra[0], stra[1]))
                    if (!(end[0] && end[1])) {
                      end[0] = stra[0]
                      end[1] = stra[1]
                    }
                    driving.search(new AMap.LngLat(state.lastLocationCoordinate[0], state.lastLocationCoordinate[1]), new AMap.LngLat(end[0], end[1]), {
                      waypoints: nuber
                    })
                    var startIcon = new AMap.Icon({
                      // 图标尺寸
                      size: new AMap.Size(25, 34),
                      // 图标的取图地址
                      image: carimg,
                      // 图标所用图片大小
                      imageSize: new AMap.Size(44, 40),
                      // 图标取图偏移量
                      imageOffset: new AMap.Pixel(-9, -3)
                    });
                    var startMarker = new AMap.Marker({
                      position: new AMap.LngLat(state.lastLocationCoordinate[0], state.lastLocationCoordinate[1]),
                      icon: startIcon,
                      offset: new AMap.Pixel(-10, -35),
                      zIndex: 9999
                    });
                    var afterIcon = new AMap.Icon({
                      // 图标尺寸
                      size: new AMap.Size(40, 45),
                      // 图标的取图地址
                      image: stanet,
                      // 图标所用图片大小
                      imageSize: new AMap.Size(28, 38),
                      // 图标取图偏移量
                      imageOffset: new AMap.Pixel(0.4, -0.5)
                    });
                    var afterIcones = new AMap.Icon({
                      // 图标尺寸
                      size: new AMap.Size(40, 45),
                      // 图标的取图地址
                      image: tuji,
                      // 图标所用图片大小
                      imageSize: new AMap.Size(28, 38),
                      // 图标取图偏移量
                      imageOffset: new AMap.Pixel(0.4, -0.5)
                    });
                    var safterMarker = new AMap.Marker({
                      position: new AMap.LngLat(stra[0], stra[1]),
                      icon: afterIcon,
                      offset: new AMap.Pixel(-10, -35),
                      zIndex: 9999
                    });
                    for (let index = 0; index < nuber.length; index++) {
                      if (nuber[index] != nuber[0]) {
                        var safterMarkeres = new AMap.Marker({
                          position: new AMap.LngLat(nuber[index].lng, nuber[index].lat),
                          icon: afterIcones,
                          offset: new AMap.Pixel(-10, -35),
                          zIndex: 9999
                        });
                        map.add([safterMarkeres]);
                      }
                    }
                    var endIcon = new AMap.Icon({
                      // 图标尺寸
                      size: new AMap.Size(40, 45),
                      // 图标的取图地址
                      image: endnet,
                      // 图标所用图片大小
                      imageSize: new AMap.Size(28, 38),
                      // 图标取图偏移量
                      imageOffset: new AMap.Pixel(0.4, -0.5)
                    });
                    var endMarker = new AMap.Marker({
                      position: new AMap.LngLat(end[0], end[1]),
                      icon: endIcon,
                      offset: new AMap.Pixel(-15, -30),
                      zIndex: 9999

                    });
                    map.add([startMarker, safterMarker, endMarker]);
                    state.gaodeIcon.push(startMarker)
                  }
                  else {
                    Toast.fail(resde.msg)
                  }
                });
                state.timet = setInterval(() => {
                  GetDriverLocation(res.data.orderNo).then((resde) => {
                    if (resde.code == 1) {
                      state.lastLocationCoordinate = resde.data.lastLocationCoordinate.split(',')
                      let stra = state.startCoordinate.split(',');
                      stra.endCoordinate = res.data.wayCoordinates
                      let nuber = []
                      for (let index = 0; index < stra.endCoordinate.length - 1; index++) {
                        if (stra.endCoordinate[index].orderStatus == OrderStatusEnum.Cancelled) {
                          /*  continue  */
                        }
                        if (stra.endCoordinate[index].endCoordinate) {
                          let user = stra.endCoordinate[index].endCoordinate
                          let ender = user.split(',')
                          nuber.push(new AMap.LngLat(ender[0], ender[1]))
                        } else {
                          nuber.push(new AMap.LngLat(stra[0], stra[1]))
                        }
                      }

                      let end = stra.endCoordinate[stra.endCoordinate.length - 1].endCoordinate.split(',')
                      nuber.unshift(new AMap.LngLat(stra[0], stra[1]))
                      if (!(end[0] && end[1])) {
                        end[0] = stra[0]
                        end[1] = stra[1]
                      }
                      driving.search(new AMap.LngLat(state.lastLocationCoordinate[0], state.lastLocationCoordinate[1]), new AMap.LngLat(end[0], end[1]), {
                        waypoints: nuber
                      })

                      var startIcon = new AMap.Icon({
                        // 图标尺寸
                        size: new AMap.Size(25, 34),
                        // 图标的取图地址
                        image: carimg,
                        // 图标所用图片大小
                        imageSize: new AMap.Size(44, 40),
                        // 图标取图偏移量
                        imageOffset: new AMap.Pixel(-9, -3)
                      });
                      var startMarker = new AMap.Marker({
                        position: new AMap.LngLat(state.lastLocationCoordinate[0], state.lastLocationCoordinate[1]),
                        icon: startIcon,
                        offset: new AMap.Pixel(-10, -35),
                        zIndex: 9999

                      });
                      var afterIcon = new AMap.Icon({
                        // 图标尺寸
                        size: new AMap.Size(40, 45),
                        // 图标的取图地址
                        image: stanet,
                        // 图标所用图片大小
                        imageSize: new AMap.Size(28, 38),
                        // 图标取图偏移量
                        imageOffset: new AMap.Pixel(0.4, -0.5)
                      });
                      var safterMarker = new AMap.Marker({
                        position: new AMap.LngLat(stra[0], stra[1]),
                        icon: afterIcon,
                        offset: new AMap.Pixel(-10, -35),
                        zIndex: 9999

                      });
                      var endIcon = new AMap.Icon({
                        // 图标尺寸
                        size: new AMap.Size(40, 45),
                        // 图标的取图地址
                        image: endnet,
                        // 图标所用图片大小
                        imageSize: new AMap.Size(28, 38),
                        // 图标取图偏移量
                        imageOffset: new AMap.Pixel(0.4, -0.5)
                      });
                      var endMarker = new AMap.Marker({
                        position: new AMap.LngLat(end[0], end[1]),
                        icon: endIcon,
                        offset: new AMap.Pixel(-15, -30),
                        zIndex: 9999

                      });
                      let mapts = [startMarker, safterMarker, endMarker]
                      map.add(mapts);
                      state.gaodeIcon[0].setMap(null);
                      state.gaodeIcon.push(startMarker);
                      state.gaodeIcon.shift();
                    }
                    else {

                    }
                  });

                }, process.env.VUE_APP_DELIVERY_LOCATION_TIME)
              }
              else {
                let stra = state.startCoordinate.split(',');
                stra.endCoordinate = res.data.wayCoordinates
                let nuber = []
                for (let index = 0; index < stra.endCoordinate.length - 1; index++) {
                  if (stra.endCoordinate[index].orderStatus == OrderStatusEnum.Cancelled) {
                    /*  continue */
                  }
                  if (stra.endCoordinate[index].endCoordinate) {
                    let user = stra.endCoordinate[index].endCoordinate
                    let ender = user.split(',')
                    nuber.push(new AMap.LngLat(ender[0], ender[1]))
                  } else {
                    nuber.push(new AMap.LngLat(stra[0], stra[1]))
                  }
                }
                let end = stra.endCoordinate[stra.endCoordinate.length - 1].endCoordinate.split(',')
                if (!(end[0] && end[1])) {
                  end[0] = stra[0]
                  end[1] = stra[1]
                }
                /*  nuber.unshift(new AMap.LngLat(stra[0],stra[1])) */
                driving.search(new AMap.LngLat(stra[0], stra[1]), new AMap.LngLat(end[0], end[1]), {
                  waypoints: nuber
                })
              }
            })
          }
          //计价显示隐藏
          /*   GetOrderFreightCal(orderNo).then((res) => {
              if (res.code == 1) {
                state.OrderFreightCal = res.data;
              }
              else {
                state.islinkamountPayable = false;
                state.islinktotalAmount = false;
                state.closetotalAmount = true
                state.closeamountPayable = true
              }
            }) */
        })
      }

    });

    const back = () => {
      clearInterval(state.timet);
      if (router.currentRoute.value.query.userType == "Driver" && !router.currentRoute.value.query.orderStatus) {
        router.back(-1)
      }
      if (router.currentRoute.value.query.userType == "User" && !router.currentRoute.value.query.orderStatus) {
        router.back(-1)
      }
      if (router.currentRoute.value.query.userType == "User" && router.currentRoute.value.query.orderStatus != 0) {
        router.replace({ path: "/Order/UserOrderList", query: { orderStatus: router.currentRoute.value.query.orderStatus } })
      }
      else if (router.currentRoute.value.query.userType == "Driver" && router.currentRoute.value.query.orderStatus != 0) {
        router.replace({ path: "/Order/DriverOrderList", query: { orderStatus: router.currentRoute.value.query.orderStatus } })
      }
      else if (router.currentRoute.value.query.userType == "User" && router.currentRoute.value.query.orderStatus == 0) {
        router.replace({ path: "/Order/UserOrderList", query: { orderStatus: router.currentRoute.value.query.orderStatus } })
      }
      else if (router.currentRoute.value.query.userType == "Driver" && router.currentRoute.value.query.orderStatus == 0) {
        router.replace({ path: "/Order/DriverOrderList", query: { orderStatus: router.currentRoute.value.query.orderStatus } })
      }
      else {
        BackOff(router.currentRoute.value)
        router.back(-1)
      }
    };
    const ToView = (val) => {
      clearInterval(state.timet);
      router.push(val);
    };
    const condition = (val) => {
      if (val == 1) {
        return '距离'
      }
      else if (val == 2) {
        return '物品重量'
      }
      else if (val == 3) {
        return '物品体积'
      }
      else {
        return '物品面积'
      }
    };
    const conditions = (val) => {
      if (val == 1) {
        return 'km'
      }
      else if (val == 2) {
        return 'kg'
      }
      else if (val == 3) {
        return 'm³'
      }
      else if (val == 4) {
        return '㎡'
      }
      else {
        return ''
      }
    };
    const maixun = (val) => {
      if (val >= 0) {
        return val + '元'
      }
      else {
        return ''
      }
    };
    const oderstaadd = (val, lnglat) => {
      let conserte = JSON.parse(decodeURIComponent(getCookie("location")))
      if (conserte) {
        var stder = {
          lat: conserte.lat,
          lng: conserte.lng
        }
        var endder = {
          lat: Number(lnglat.split(",")[1]),
          lng: Number(lnglat.split(",")[0])
        }
        var min = GetDistance(stder, endder)
        if (min >= process.env.VUE_APP_DELIVERED_DISTANCE) {
          return Toast.fail("未到达目的地,不能点击已送达")
        }
      } else {
        return Toast.fail("无法获取到定位")
      }
      const orderNo = getUrlParam("orderNo");
      clearInterval(state.timet);
      router.push({ name: "DeliveredOrderImg", query: { orderNo: orderNo, deliveryNo: val } });
      /*   Delivered(pare).then((res) => {
          if (res.code == 1) {
            for (let index = 0; index < state.wayCoordinates.length; index++) {
              if (state.wayCoordinates[index].deliveryNo == pare.deliveryNo) {
                state.wayCoordinates[index].orderStatus = OrderStatusEnum.Delivered;
                state.wayCoordinates[index].orderStatusDisplaytow = false
                state.wayCoordinates[index].orderStatusDisplay = true
              }
              const orderNo = getUrlParam("orderNo");
              const DriverOrderNo = getUrlParam("DriverOrderNo");
              let params = {
                orderNo: orderNo,
                DriverOrderNo: DriverOrderNo
              }
              userOrderDetails(params).then((res) => {
                if (res.code == 1) {
                  state.wayCoordinates = res.data.wayCoordinates;
                  for (let index = 0; index < state.wayCoordinates.length; index++) {
                    state.wayCoordinates[index].orderStatusDisplay = true;
                    state.wayCoordinates[index].SubNavigation = true;
                    state.wayCoordinates[index].orderStatusDisplaytow = false;
                    if (state.wayCoordinates[index].orderStatus == OrderStatusEnum.Delivering) {
                      state.wayCoordinates[index].orderStatusDisplaytow = true
                      state.wayCoordinates[index].orderStatusDisplay = false
                    }
                    else if (state.wayCoordinates[index].orderStatus >= OrderStatusEnum.Delivered) {
                      state.wayCoordinates[index].SubNavigation = false;
                    }
                  }
                  if (res.data.orderStatus == OrderStatusEnum.Delivered) {
                    state.Suborderstatus = false;
                    state.isShowevaluationBtn = false;
                    Dialog.confirm({
                      title: "订单已送达",
                      message:
                        '订单已送达,您要继续接单吗?',
                    })
                      .then(() => {
                        router.push({ name: 'DriverOrder' })
                      })
                      .catch(() => {
                        // on cancel
                      });
                  }
                }
              })
            }
          }
          else {
            Toast.fail(res.msg);
          }
        }) */
    };
    const loading = () => {
      Dialog.confirm({
        title: '送货',
        message: '确定开始送货',
      }).then(() => {
          const orderNo = getUrlParam("orderNo");
          var pare = {
            orderNo: orderNo,
            driverId: "",
            version: 0
          }
          DriverDeliveringOrder(pare).then((res) => {
            if (res.code == 1) {
              state.Delivery = false
              state.isShowevaluationBtn = false;
              for (let index = 0; index < state.wayCoordinates.length; index++) {
                state.wayCoordinates[index].orderStatus = OrderStatusEnum.Delivering;
              }
              for (let index = 0; index < state.wayCoordinates.length; index++) {
                if (state.wayCoordinates[index].orderStatus == OrderStatusEnum.Delivering) {
                  state.wayCoordinates[index].orderStatusDisplaytow = true
                  state.wayCoordinates[index].orderStatusDisplay = false
                }
              }

            }
            else {
              Toast.fail(res.msg);
            }
          })
        })
        .catch(() => {
          // on cancel
        });

    };
    const loadingConfirmPickUpOrder = () => {
      const orderNo = getUrlParam("orderNo");
      DriverArrivePickUpOrder({ orderNo: orderNo }).then((res) => {
        if (res.code == 1) {
          state.DeliveryConfirmPic = false;
          state.isShowevaluationBtn = false;
          state.Delivery = true;
          state.orderInfo.orderStatus = OrderStatusEnum.ArrivePickUp;
          Toast.success("取货中")
        } else {
          Toast.fail(res.msg)
        }
      });
    }
    const Navigation = (index) => {
      state.coordinate = state.wayCoordinates[index].endCoordinate
      state.Endname = state.wayCoordinates[index].endDetailAddress
      show.value = true

    };
    const actions = [
      { name: '高德地图' },
      { name: '百度地图' },
    ];
    const onSelect = (item) => {
      if (navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0) {
        wxshow.value = true;
        shows.value = false;
        return
      }
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      let cont = state.coordinate.split(',')
      if (!(cont[0] && cont[1])) {
        show.value = false;
        return Toast.fail("地址不准确请联系发单人")
      }
      if (item.name == '高德地图') {
        if (isiOS) {
          window.location.href = "iosamap://path?sourceApplication=applicationName&sid=BGVIS1&did=BGVIS2&dlat=" + cont[1] + "&dlon=" + cont[0] + "&dname=" + state.Endname + "&dev=0&t=0";
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          window.setTimeout(function () {
            router.push({ path: "/Order/MapDownload", query: { Map: "高德地图" } })
          }, 3000);
        }
        if (isAndroid) {
          window.location.href = "amapuri://route/plan/?sid=BGVIS1&did=BGVIS2&dlat=" + cont[1] + "&dlon=" + cont[0] + "&dname=" + state.Endname + "&dev=0&t=0";
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          window.setTimeout(function () {
            router.push({ path: "/Order/MapDownload", query: { Map: "高德地图" } })
          }, 3000);
        }
      }
      else {
        if (isiOS) {

          window.location.href = "baidumap://map/direction?origin=&destination=" + GCJ02ToBD09(cont[1], cont[0]).lat + "," + GCJ02ToBD09(cont[1], cont[0]).lng + "&destination_region=" + state.Endname + "&mode=driving&src=ios.baidu.openAPIdemo"
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          window.setTimeout(function () {
            router.push({ path: "/Order/MapDownload", query: { Map: "百度地图" } })
          }, 3000);
        }
        if (isAndroid) {
          window.location.href = "baidumap://map/direction?origin=&destination=?origin=name:" + state.Endname + "|latlng:" + GCJ02ToBD09(cont[1], cont[0]).lat + "," + GCJ02ToBD09(cont[1], cont[0]).lng + "&mode=car&sy=3&index=0&target=1&src=andr.baidu.openAPIdemo"
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          window.setTimeout(function () {
            router.push({ path: "/Order/MapDownload", query: { Map: "百度地图" } })
          }, 3000);
        }
      }
      show.value = false;
      /* state.showPicker = false;  */
    };
    const Navigationtes = () => {
      shows.value = true
    };
    const actionses = [
      { name: '高德地图' },
      { name: '百度地图' },
    ];
    const onSelectes = (item) => {
      if (navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0) {
        wxshow.value = true;
        shows.value = false;
        return
      }

      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      let consrte = state.orderInfo.startCoordinate.split(',')
      if (!(consrte[0] && consrte[1])) {
        shows.value = false;
        return Toast.fail("地址不准确请联系发单人")
      }
      if (item.name == '高德地图') {
        if (isiOS) {
          window.location.href = "iosamap://path?sourceApplication=applicationName&sid=BGVIS1&did=BGVIS2&dlat=" + consrte[1] + "&dlon=" + consrte[0] + "&dname=" + state.orderInfo.startDetailAddress + "&dev=0&t=0";
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          window.setTimeout(function () {
            router.push({ path: "/Order/MapDownload", query: { Map: "高德地图" } })
          }, 3000);
        }
        if (isAndroid) {
          window.location.href = "amapuri://route/plan/?sid=BGVIS1&did=BGVIS2&dlat=" + consrte[1] + "&dlon=" + consrte[0] + "&dname=" + state.orderInfo.startDetailAddress + "&dev=0&t=0";
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          window.setTimeout(function () {
            router.push({ path: "/Order/MapDownload", query: { Map: "高德地图" } })
          }, 3000);
        }
      }
      else {
        if (isiOS) {
          window.location.href = "baidumap://map/direction?origin=&destination=" + GCJ02ToBD09(consrte[1], consrte[0]).lat + "," + GCJ02ToBD09(consrte[1], consrte[0]).lng + "&destination_region=" + state.orderInfo.startDetailAddress + "&mode=driving&src=ios.baidu.openAPIdemo";
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          window.setTimeout(function () {
            router.push({ path: "/Order/MapDownload", query: { Map: "百度地图" } })
          }, 3000);
        }
        if (isAndroid) {
          window.location.href = "baidumap://map/direction?origin=&destination=?origin=name:" + state.orderInfo.startDetailAddress + "|latlng:" + GCJ02ToBD09(consrte[1], consrte[0]).lat + "," + GCJ02ToBD09(consrte[1], consrte[0]).lng + "&mode=car&sy=3&index=0&target=1&src=andr.baidu.openAPIdemo"
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
          });
          window.setTimeout(function () {
            router.push({ path: "/Order/MapDownload", query: { Map: "百度地图" } })
          }, 3000);
        }
      }
    };
    const payment = (val) => {
      const orderStatus = getUrlParam("orderStatus");
      router.push({ path: "/Pay/Index", query: { outTradeNo: val } })
    };
    const UserdeFineduser = (val) => {
      password.value = "";
      state.orderCompletion = [];
      state.Inputbox = true;
      showKeyboard.value = true;
      showINput.value = true;
      let params = { val }
      state.orderCompletion.push(params)
    };
    watch(password, (newVal) => {
      if (newVal.length == 6) {
        UpdateUserConfirmOrder({ orderNo: state.orderCompletion[0].val, payPassword: passwordMd5(newVal) }).then((res) => {
          if (res.code == 1) {
            Toast.success("确认送达")
            state.isShoweva = false;
            state.isShowevaluationBtn = true;
            state.bottom = "评价";
            showINput.value = false;
            showKeyboard.value = false;
          }
          else {
            Toast.fail(res.msg)
          }
        })
      }
    })
    const redPackDiscountAmount = () => {
      if (state.orderInfo.redPackDiscountAmount == 0) {
        state.redPackDiscountAmount = false;
      }
      else {
        state.redPackDiscountAmount = true;
      }
    };
    const couponDiscountAmount = () => {
      if (state.orderInfo.couponDiscountAmount == 0) {
        state.couponDiscountAmount = false;
      }
      else {
        state.couponDiscountAmount = true;
      }
    };
    const systemDiscountAmount = () => {
      if (state.orderInfo.systemDiscountAmount == 0) {
        state.systemDiscountAmount = false;
      }
      else {
        state.systemDiscountAmount = true;
      }
    };
    const userTipsAmount = () => {
      if (state.orderInfo.userTipsAmount == 0) {
        state.userTipsAmount = false;
      }
      else {
        state.userTipsAmount = true;
      }
    };
    const getSpeicalTime = () => {
      var now = new Date;
      now.setMinutes(now.getMinutes() - 10);
      return now;
    }
    const ConfirmPickUpOrder = (val, index) => {
      UpdateUserConfirmPickUpOrder({ orderNo: val }).then((res) => {
        if (res.code == 1) {
          state.ConfirmPic = false;
          state.orderInfo.orderStatus = OrderStatusEnum.ConfirmPickUp;
          Toast.success("装货成功")
        } else {
          Toast.fail(res.msg)
        }
      });
    };
    const chat = () => {
      let userRoomName = "";
      let roomChatTag = "";
      const userType = getUrlParam("userType");
      if (userType == "Driver") {
        userRoomName = state.orderInfo.userNickName
        roomChatTag = "客户";
      }
      if (userType == "User") {
        userRoomName = state.orderInfo.driverRealName
        roomChatTag = "司机";
      }
      router.push({ name: "ChatUserMsg", query: { roomId: state.orderInfo.chatRoomId, userRoomName: userRoomName, roomChatTag: roomChatTag } })
    }
    const XerPOrder = () => {
      router.push({ name: "XerpOrderDetails", query: { XerPUrl: state.XerPUr } });
    };
    const imgOrder = (val) => {
      state.ImgSegmentation = [];
      showOrderImg.value = true;
      for (let index = 0; index < state.wayCoordinates.length; index++) {
        if (state.wayCoordinates[index].deliveryNo == val) {
          if (state.wayCoordinates[index].deliveredImg) {
            let urlImg = state.wayCoordinates[index].deliveredImg.split("|");
            for (let j = 0; j < urlImg.length; j++) {
              state.ImgSegmentation.push(urlImg[j].replace(process.env.VUE_APP_API_BASE, ""));
            }
          }
        }
      }
    };
    const ImgEnlargement = (val) => {
      ImagePreview({
        images: [val],
        showIndex: false,
      })
    }
    return {
      state,
      getSpeicalTime,
      UserdeFineduser,
      XerPOrder,
      payment,
      Navigationtes,
      userTipsAmount,
      systemDiscountAmount,
      redPackDiscountAmount,
      couponDiscountAmount,
      decimal,
      show,
      shows,
      wxshow,
      actions,
      actionses,
      onSelect,
      activeNames,
      onSelectes,
      loading,
      loadingConfirmPickUpOrder,
      ConfirmPickUpOrder,
      Navigation,
      oderstaadd,
      back,
      maixun,
      ToView,
      SetCancelEvaluationRouter,
      showOrderImg,
      imgOrder,
      ImgEnlargement,
      orderStatustonn, conditions, condition, showINput, showKeyboard, password, chat, OrderStatusEnum, XerPUrl, onLoad, createRoute, createStartMarker, searchResult, startOnLoad, startCreateStartMarker, startSearchResult,
    };
  }
};
</script>
<style>
#mapDiv {
  width: 100%;
  height: 19rem;
}
.img-order {
  margin: auto;
  position: relative;
  font-family: 微软雅黑;
  font-weight: 500;
  margin-top: 1rem;
}
.img-row-order {
  text-align: center;
  margin-top: 0.5rem;
}
.img-image-order {
  position: relative;
  margin-top: 0.5rem;
}
</style>
